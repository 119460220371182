import { useState } from "react"
import { TopBar } from "../../_shared/TopBar"
import './webinars.scss'
export const RouteWebinars = () => {
    const [webinars, setWebinars] = useState([
        {
            title: "How Religion Can Create Community",
            speaker: "Robin Dunbar, PhD",
            description: "Robin is a Professor of evolutionary psychology at the University of Oxford and has authored several books including 'How Many Friends Does One Person Need?'",
            date: "feb 6 2025"
        },
        // {
        //     title: "The value of friendship",
        //     speaker: "Robin Dunbar, PhD",
        //     description: "Robin is a Professor of evolutionary psychology at the University of Oxford and has authored several books including 'How Many Friends Does One Person Need?'",
        //     date: "mar 7 2024"
        // },
        // {
        //     title: "Influences on child development",
        //     speaker: "Mary Ngure",
        //     description: "Mary is a counselling psychologist who researches child wellbeing and mental health. She promotes an inclusive approach with parents, schools, and communities.",
        //     date: "Apr 4 2024"
        // },
        // // {
        // //     title: "Navigating narcissism",
        // //     speaker: "Darlene Lancer",
        // //     description: "Darlene is a family and marriage therapist specialising in co-dependency and boundary setting. Her 12 books include 'Dealing with a narcissist'.",
        // //     date: "May 2 2024"
        // // },
        // {
        //     title: "Picking positive patterns",
        //     speaker: "Lydia Ebdon",
        //     description: "Lydia is a psychologist whose research distinguishes subconscious patterns from more conscious moderation. Learn about picking and maintaining helpful patterns.",
        //     date: "May 2 2024"
        // },
        // {
        //     title: "Social influences and self-esteem",
        //     speaker: "Pamela Onyango, PhD",
        //     description: "Pamela is a psychology lecturer at the University of Nairobi. She has authored several book chapters and journal articles.",
        //     date: "Jun 6 2024"
        // },
        // {
        //     title: "Stress and anxiety management",
        //     speaker: "Margaret Kagwe, PhD",
        //     description: "Margaret is a Senior Consultant at Esteem Counselling and Consultancy. She uses preventive psychology and founded the Mental Health Champion Initiative Kenya.",
        //     date: "Jul 4 2024"
        // },
        // {
        //     title: "Death, grief, and healing",
        //     speaker: "Geoffrey Wango, PhD",
        //     description: "Geoffrey is Assistant Professor in counselling at the University of Nairobi, specialising in person-centred therapy to transform lives for the better",
        //     date: "Aug 1 2024"
        // },
        // {
        //     title: "Surviving workplace toxicity",
        //     speaker: "Jennifer Wangari, PhD",
        //     description: "Jennifer is a lecturer at the Catholic University of East Africa with interests in educational psychology and project management.",
        //     date: "Sep 5 2024"
        // },
        // {
        //     title: "Trauma-informed care",
        //     speaker: "Elizabeth Peters",
        //     description: "Elizabeth is a mental health activist, currently finalising her Masters in psychology and running mental wellness campaigns through Lifted Hearts Africa.",
        //     date: "Oct 3 2024"
        // },
        // {
        //     title: "Depression and suicide",
        //     speaker: "Charles Kimamo, PhD",
        //     description: "Charles is an Associate Professor of psychology at the University of Nairobi and founder and Chair of ABSK. He has authored several book chapters and many journal articles.",
        //     date: "Nov 7 2024"
        // },
        // {
        //     title: "Surviving stigma in illness",
        //     speaker: "Carren Anyango",
        //     description: "Carren is an exceptional leader and a team player, a strategic think tank with experience in Nursing, Psychology(IO), HIV care, management and training.",
        //     date: "dec 5 2024"
        // }
    ])
    return <div className="screen-webinars">
        <TopBar />
        <div className="container">
            <div className="navbar-header">
                <button className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="icon icon-bar" />
                    <span className="icon icon-bar" />
                    <span className="icon icon-bar" />
                </button>
                <a href="index" className="navbar-brand">
                    <span className="image-text">ABSK</span> <sup>Africa</sup>
                </a>
            </div>
            <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav navbar-nav-first">
                </ul>
                <ul className="nav navbar-nav navbar-right">
                    <li className="active"><a href="/index#home" className="smoothScroll">Home</a></li>
                    <li><a style={{color:"goldenrod"}} href="/webinars" className="smoothScroll">Events</a></li>
                    <li><a href="/index#courses" className="smoothScroll">Courses</a></li>
                    <li><a href="/index#counselling" className="smoothScroll">Counselling</a></li>
                    <li><a href="/index#about" className="smoothScroll">About</a></li>
                    <li><a href="/index#contact" id="open-mailer" className="smoothScroll">Contacts</a></li>
                    {/* <li className="section-btn african-but"><a href="#" data-toggle="modal" data-target="#modal-form">Members / Sign in</a></li> */}
                </ul>
            </div>
        </div>
        <h1 className="top-title">Upcoming Events</h1>
        <p className="top-info">Register for the ABSK webinar series here and we will email you a link before each webinar.</p>
        {webinars.map((eventt,index) => {
            return <div className="webinar-banner" key={index}>
                <div className="date-label">
                    <div>{eventt.date.split(" ")[0]} {eventt.date.split(" ")[1]}</div>
                    {/* <div></div> */}
                    <div>{eventt.date.split(" ")[2]}</div>
                </div>
                <div className="left">
                    <h1>{eventt.title}</h1>
                    <h4>by {eventt.speaker}</h4>
                    <p>{eventt.description}</p>
                </div>
                <div className="right"></div>
                {/* <div>
                    <h1>{eventt.title}</h1>
                    <p>{eventt.description}</p>
                </div> */}
                <a href="/webinar-registration" className="register-bt">Register</a>
            </div>
        })}
    </div>
}